<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div
      class="c-stats__overlay"
      ref="overlay"
      v-if="visible"
      :class="sideClass"
      :style="'height: '+ height + 'px'"
    >
      <div
        class="c-stats__item"
        v-for="(stat, i) in stats"
        :key="'stats_'+i"
      >
        <span>{{ stat.number }}</span>
        <span>{{ stat.text }}</span>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'stats-grouped-overlay',
  props: {
    /**
     * Allow the timer to be configured through the parent component. Subtract the 1s + 1s transition animations!
     */
    delay: {
      type: Number,
      default: 9000
    }
  },
  data() {
    return {
      // Used by transition animation
      visible: true,

      // Which stat panel is currently visible
      side: 'left',

      // References for cleanup on destroy
      interval: null,
      timer: null,

      // Used in setting the height based on the width of the overlay
      height: '0px'
    }
  },
  computed: {
    sideClass() {
      return 'c-stats__overlay--' + this.side;
    },
    /**
     * Construct a list of stats by id based on which side is active
     * Updates whenever the stats in the store change, or the side changes
     */
    stats() {
      let arr,

        // Get the stats from the store
        stats = this.$store.state.stats.stats;

      if (this.side === 'left') {
        arr = [
          this.getStatObjectById({ stats: stats, id: 'checkins-today' }),
          this.getStatObjectById({ stats: stats, id: 'questions' }),
          this.getStatObjectById({ stats: stats, id: 'locations' }),
          this.getStatObjectById({ stats: stats, id: 'matches' })
        ];
      } else {
        arr = [
          this.getStatObjectById({ stats: stats, id: 'checkins-alltime' }),
          this.getStatObjectById({ stats: stats, id: 'answers' }),
          this.getStatObjectById({ stats: stats, id: 'countries' }),
          this.getStatObjectById({ stats: stats, id: 'matches' })
        ];
      }

      // Avoid undefined items
      return arr.filter((item) => {
        return item !== undefined;
      });
    },
  },
  mounted() {
    this.makeOverlaySquare();
    this.interval = setInterval(this.changeSide, this.delay)
  },
  beforeDestroy() {
    clearInterval(this.interval)
    clearTimeout(this.timer);
  },
  methods: {
    /** 
     * Change the visible side, take into account the animation time of 1s
     */
    changeSide() {
      let vm = this;
      this.visible = false;

      // Delay for the transition animation + 50ms
      this.timer = setTimeout(() => {
        vm.side = vm.side === 'left' ? 'right' : 'left';
        vm.visible = true;
      }, 1050);
    },
    /**
     * Find and return a stat object based on id
     * TODO: Maybe put this in the store?
     */
    getStatObjectById({ stats, id }) {
      return stats[id];
    },
    /**
     * Get the computed width of the overlay element and set it as the height
     */
    makeOverlaySquare() {
      this.height = this.$refs.overlay.clientWidth
    }
  }
}
</script>


<style scoped lang="scss">
.c-stats {
  &__overlay {
    position: absolute;
    top: 25%;
    left: 5%;
    width: 30%;
    height: 30%;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: space-between;
    border-radius: 8px;

    &--right {
      right: 5%;
      left: auto;
    }
  }

  &__item {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: calc(50% - 10px);
    height: calc(50% - 10px);
    text-align: center;
    padding: 10px;
    color: white;
    background: $primaryColor;

    span:first-child {
      height: auto;
      font-weight: bold;
      font-size: 3vw;
    }

    span {
      height: 50%;
      display: block;
      font-size: 1.6vw;
    }

    &:nth-child(2n) {
      background: $tertiaryColor;
    }
    &:nth-child(3n) {
      background: $yellow;
    }
    &:nth-child(4n) {
      background: $gray;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>