import Vue from "vue";
import VueRouter from "vue-router";

import Greetings from '@/components/slides/Greetings'
import Coffee from '@/components/slides/Coffee'
import GetConnected from '@/components/slides/GetConnected'
import KnowledgeTags from '@/components/slides/KnowledgeTags'
import Welcome from '@/components/slides/Welcome'
import Meetings from '@/components/slides/Meetings'
import StaticMap from '@/components/slides/StaticMap'
import Slides from '@/components/Slides'
import Location from '@/components/Location'
import Error from '@/components/Error'
import Loader from '@/components/Loader'

Vue.use(VueRouter);

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Start',
      component: Location
    },
    {
      path: '/:appKey?',
      name: 'Location',
      component: Location
    },
    // {
    //   path: '/:locationid?/:appKey?',
    //   name: 'Location',
    //   component: Location
    // },
    {
      path: '/slides',
      name: 'Slides',
      component: Slides,
      children: [
        {
          path: '/slides/greetings',
          name: 'Greetings',
          component: Greetings
        },
        {
          path: '/slides/welcome',
          name: 'Welcome',
          component: Welcome
        },
        {
          path: '/slides/meetings',
          name: 'Meetings',
          component: Meetings
        },
        {
          path: '/slides/coffee',
          name: 'Coffee',
          component: Coffee
        },
        {
          path: '/slides/knowlegde-tags',
          name: 'KnowledgeTags',
          component: KnowledgeTags
        },
        {
          path: '/slides/get-connected',
          name: 'GetConnected',
          component: GetConnected
        },
        {
          path: '/slides/map',
          name: 'StaticMap',
          component: StaticMap
        }
      ]
    },
    {
      path: '/error',
      name: 'Error',
      component: Error
    },
    {
      path: '/loading',
      name: 'Loader',
      component: Loader
    }
  ]
})
