import applicationAPI from '../../services/api/modules/application';
import axios from 'axios';
import { api } from '../../config'

const state = {
  applicationKey: '',
  application: {}
}

const getters = {
  applicationKeyFromStore: () => localStorage.getItem('applicationKey') || '',
  applicationApiToken: state => state.application.ApiToken || '',
  applicationProfileToken: state => state.application.ProfileToken || null,
}

const mutations = {
  setApplicationKey: (state, newState) => state.applicationKey = newState,
  setApplication: (state, newState) => state.application = newState
}

const actions = {
  /**
   * Get application by key
   * @returns application object
   */
  async getApplicationByKey({ state, commit, dispatch, getters }) {
    let response = null

    let _applicationKey = state.applicationKey || getters.applicationKeyFromStore
    localStorage.setItem('applicationKey', _applicationKey)
    
    try {
      response = await applicationAPI.getApplicationByKey(state.applicationKey)
      if (response.status === 200 && response.data.Type === 'TvApp') {
        commit('setApplication', response.data)
        response = response.data
        localStorage.setItem('applicationKey', state.applicationKey)

        /**
         * Update api headers with application cridentials
         */
        let apiHeaders = Object.assign(api.headers, {
          apitoken: response.applicationApiToken || api.headers.apitoken,
          profiletoken: response.ProfileToken
        })
        let updatedHeaders = Object.assign(axios.defaults.headers.common, apiHeaders)
        axios.defaults.headers.common = updatedHeaders
      }
    }
    catch { }

    /**
     * When response is null:
     * - Clear all store data
     * - Clear all local storage data like application key, location ID, logo image and namen
     */
    if (response === null || response.status === 204) {
      dispatch('clearAllApplicationData', '')
    }

    return response
  },

  async changeApplication({ commit, dispatch }, appKey) {
    commit('setApplicationKey', appKey)
    let output = null
    try {
      let response = await dispatch('getApplicationByKey')
      dispatch('changeLocation', { locationId: response.Locations[0].LocationId }, { root: true })
      output = response
    } 
    catch { }
    return output
  },

  /**
   * Clear all store and local storage data
   */
  clearAllApplicationData({ commit }) { 
    commit('setApplicationKey', '')
    commit('setApplication', {})

    // Clear checkins list state data 
    commit('setCheckins', [], { root: true })

    // Clear location state data
    commit('location_change_name', '', { root: true })
    commit('location_change_logo', '', { root: true })
    commit('location_change_id', 0, { root: true })
    
    // Clear local storage
    localStorage.setItem('locationId', 0)
    localStorage.setItem('locationLogo', '')
    localStorage.setItem('locationName', '')
  }
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};