/**
 * Check how many days are between current day
 * @param {Date} dateValue
 * @param {Date} compareDate
 */
export const daysBetweenCurrentDate = (dateValue, compareDate = null) => {
  let currentDate = new Date()
  if (compareDate !== null) {
    currentDate = compareDate
  }

  let one = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  )
  let two = new Date(
    dateValue.getFullYear(),
    dateValue.getMonth(),
    dateValue.getDate()
  )

  // Do the math.
  let millisecondsPerDay = 1000 * 60 * 60 * 24
  let millisBetween = two.getTime() - one.getTime()
  let days = millisBetween / millisecondsPerDay

  // Round down.
  return Math.floor(days)
}

export const dateObjectIsoDateString = (dateObj) => { 
  if (typeof dateObj !== 'undefined') {
    let newDateObj = new Date()
    if (typeof dateObj === 'string') {
      newDateObj = parseIsoDateStringToDate(dateObj)
    } else {
      newDateObj = dateObj
    }
    let day = newDateObj.getDate().toString()
    let month = (newDateObj.getMonth() + 1).toString()
    let year = newDateObj.getFullYear().toString()

    if (month.length === 1) {
      month = '0' + month.toString()
    }

    if (day.length === 1) {
      day = '0' + day.toString()
    }

    return year + '-' + month + '-' + day
  }
  return ''
} 

/**
 * Internal functions
 */
function parseIsoDateStringToDate(dateString) {
  let time = ('' + dateString).replace(/-/g, '/').replace(/[TZ]/g, ' ')
  let date = new Date(time)
  return date
}