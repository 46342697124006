/**
 * Import Dependency
 */
import Meeting from '../../services/models/meeting'
import { capitalizeFirstLetter } from '../../services/helpers/string'
import { dateObjectIsoDateString } from '../../services/helpers/date'
import store from '../../store'
import { isSmallThanFullHD } from '../../services/helpers/mediaQueries'

/**
 * Import API
 */
import meetingsAPI from '../../services/api/modules/meetings'

/**
 * Declare Variable
 */
const state = {
  page: 1, // position of meeting set
  meetings: [] // meetings, used as pup-up queue
}

const getters = {
  getMeetingCount: state => state.meetings.length,
  getMeetingsPageNumber: state => state.page,
  getMeetings: state => state.meetings
}

const actions = {
  getLocationMeetings: context => {
    let _startDate = dateObjectIsoDateString(new Date())
    let _endDate = dateObjectIsoDateString(new Date())
    meetingsAPI
      .getAllLocationMeetings({ locationId: store.state.location.id, startDate: _startDate, endDate: _endDate })
      .then(res => {
        let _meetings = []
        res.forEach(space => {
          _meetings = _meetings.concat(space.Items.filter(i => i.Type !== 'Block'))
        });
        
        context.commit('loadMeetings', { meetings: _meetings.map(mapRawdataToMeeting) })
      })
      .catch(error => console.log(error))
  },
  nextMeetingsPage: context => {
    context.commit('nextMeetingsPage')
  }
}

const mutations = {
  loadMeetings: (state, { meetings }) => {
    state.meetings = meetings
  },
  nextMeetingsPage: state => {
    if (state.meetings.length > state.page * (isSmallThanFullHD() ? 2 : 3)) {
      state.page++
    } else {
      state.page = 1
    }
  }
}

/**
 * Export
 */
export default {
  state,
  getters,
  actions,
  mutations
}

/**
 * Map the raw data from the S2M API to the data we need for a Meeting
 *
 * @param input
 * @return {Meeting}
 */
let mapRawdataToMeeting = input => {
  return {
    id: input.Id,
    name: capitalizeFirstLetter(input.CompanyName),
    subject: capitalizeFirstLetter(input.Name)
  }
}
