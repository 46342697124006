<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div v-if="active">
      <div
        class="flip-container"
        :class="{flip: backside}"
      >
        <div class="flipper">
          <div class="front">
            <div v-if="cards.front !== false">
              <checkin-card
                :data="cards.front"
                type="popUp"
              ></checkin-card>
            </div>
          </div>
          <div class="back">
            <div v-if="cards.back !== false">
              <checkin-card
                :data="cards.back"
                type="popUp"
              ></checkin-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

import Card from "./Card";
import { popupTimer } from '@/config';

export default {
  name: 'Popup',

  components: {
    'checkin-card': Card
  },

  data() {
    return {
      activated: false,
      backside: false,
      cards: {
        back: false,
        front: false
      },
      cardTimer: null
    };
  },
  computed: {
    active() {
      if (this.count_checkins !== 0) {
        return true;
      }
      return false;
    },

    count_checkins() {
      return this.$store.state.knowledge.checkins.length
    }
  },

  watch: {
    active: {
      immediate: true,
      handler: function(val) {
        if (val) {
          if (this.activated === false) {
            this.activated = true;
            this.loadData({ position: this.backside ? 'front' : 'back' });
          }
        }
        // Reset
        this.cardTimer = setTimeout(() => {
          this.backside = false;
          this.activated = false;
          this.cards.front = false;
          this.cards.back = false;
        }, 500);
      }
    },
  },

  beforeDestroy() {
    if (this.cardTimer) {
      clearTimeout(this.cardTimer)
    }
  },

  methods: {
    loadData({ position }) {
      this.cards[position] = this.$store.state.knowledge.checkins[0];
      setTimeout(() => { this.backside = !this.backside }, 500); // Rotate to new data after 2000ms.
      setTimeout(this.hideOrLoadNext, popupTimer);
    },
    hideOrLoadNext() {
      this.$store.commit("clear_current_popup");

      if (this.$store.state.knowledge.checkins.length !== 0) {
        this.loadData({ position: this.backside ? 'front' : 'back' });
      }
    }
  }
};



</script>

<style scoped>
/* entire container, keeps perspective */
.flip-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  perspective: 1000px;
  z-index: 9999;
  opacity: 0.99;
}
/* flip the pane when hovered */
.flip-container.flip .flipper {
  transform: rotateY(180deg);
}

.flip-container,
.front,
.back {
  height: 35.64rem; /*29.7rem; */
  width: 30.36rem; /* 25.3rem; */
}

/* flip speed goes here */
.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;

  position: relative;
}

/* hide back of pane during swap */
.front,
.back {
  backface-visibility: hidden;

  position: absolute;
  top: 0;
  left: 0;
}
.front > div,
.back > div {
  height: 100%;
  width: 100%;
}

/* front pane, placed above back */
.front {
  z-index: 2;
  /* for firefox 31 */
  transform: rotateY(0deg);
  /* background: #FAFAFA; */
}

/* back, initially hidden pane */
.back {
  transform: rotateY(180deg);
  /* background: #FAFAFA; */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>