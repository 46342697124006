/* eslint-disable */

<template>
  <div class="layoutTicker">
    <transition-group
      name="ticker"
      tag="div"
      mode="out-in"
    >
      <checkin-card
        v-for="card in cards"
        :data="card"
        :key="card.id"
        type="horizontal"
      ></checkin-card>
    </transition-group>
  </div>
</template>

<script>
import Card from "../components/Card";
import { tickerSpeed } from "../config";

export default {
  name: 'Ticker',
  components: {
    'checkin-card': Card
  },
  data() {
    return {
      msg: 'Ticker',
    };
  },
  computed: {
    cards() {
      let start = (this.$store.state.knowledge.ticker - 1);
      let cards = this.$store.state.knowledge.cards.slice(
        start, start + 2
      );
      return cards;
    }
  },
  mounted() {
    setInterval(() => {
      this.$store.dispatch('tickerProgress');
    }, tickerSpeed);
  }
};
</script>
<style scoped>
.ticker-item,
.c-card {
  transition: all 1s;
  display: inline-block;
}
.ticker-enter {
  opacity: 0;
  transform: translateY(30px);
}
.ticker-leave-to {
  opacity: 0;
  transform: translateX(-1000px);
}
.ticker-leave-active {
  position: absolute;
}
</style>