import Vue from 'vue'

let sizes = {
  160: '160x120_',
  320: '320x240_',
  640: '640x480_'
}

let profilePhotoSize = {
  38: '_38x38',
  84: '_84x84',
  120: '_120x120',
  150: '_150x150',
  300: '_300x300'
}

Vue.filter('getProfileImage', (filename = '', profileId = 0, size = null) => {
  let s3ProfileBucket = process.env.VUE_APP_S3_AWS_PROFILE_BUCKET

  if (filename === '' || !profileId === 0) {
    return ''
  }

  if (size === null) {
    // Original photo size
    return `https://${s3ProfileBucket}.s3.amazonaws.com/${filename}`
  } else {
    // Get the scaled photo
    let filenameNoExtension = filename.substring(0, filename.lastIndexOf('.'))

    let position = Number(filename.indexOf('.'))
    let imageLength = Number(filename.length)
    let imageExtension = filename.substring(position, imageLength)
    return `https://${s3ProfileBucket}.s3.amazonaws.com/${profileId}_${filenameNoExtension}${profilePhotoSize[size]}${imageExtension}`
  }
})

Vue.filter(
  'getLocationImage',
  (filename = '', locationId = 0, size = null) => {
    if (filename === '' || locationId === 0) {
      return '@/assets/img/placeholder_location.png'
    }
    if (size === null || size === 0) {
      // Original photo size
      return `${
        process.env.VUE_APP_AZURE_STORAGE_URL
      }/${locationId.toString()}/${filename}`
    } else {
      return getSizedJPGVersion(locationId.toString(), filename, sizes[size])
    }
  }
)

Vue.filter('getEventImageSrc', (filename = '', size = null) => {
  if (filename === '') {
    return '@/assets/img/placeholder_no_image.png'
  }
  if (size === null || size === 0) {
    return `${process.env.VUE_APP_AZURE_STORAGE_URL}/events/${filename}`
  } else {
    return getSizedJPGVersion('events', filename, sizes[size])
  }
})

function getSizedJPGVersion(prefix = '', filename = '', size = '') {
  if (prefix !== '') {
    prefix = '/' + prefix
  }

  let filenameNoExtension = filename.substring(0, filename.lastIndexOf('.'))

  let position = Number(filename.indexOf('.'))
  let imageLength = Number(filename.length)
  let imageExtension = filename.substring(position, imageLength)

  return `${process.env.VUE_APP_AZURE_STORAGE_URL}${prefix}/${size}${filenameNoExtension}${imageExtension}`
}

Vue.filter('checProfileImage', (e, dummyImg = '', hideOnError = false) => {
  let defaultURL = '@/assets/img/defaultProfileImage_v2.png'
  if (e.currentTarget.src !== defaultURL) {
    e.currentTarget.src = defaultURL
  }
})

Vue.filter('checkImage', (e, dummyImg = '', hideOnError = false) => {
  let defaultURL = '@/assets/img/placeholder.png'
  if (e.currentTarget.src !== defaultURL) {
    e.currentTarget.src = defaultURL
  }
})

Vue.filter('getOrientation', (file, returnCallback) => {
  let reader = new FileReader()
  reader.onload = function(e) {
    let view = new DataView(e.target.result)
    if (view.getUint16(0, false) !== 0xffd8) {
      return returnCallback(-2)
    }
    let length = view.byteLength
    let offset = 2
    while (offset < length) {
      let marker = view.getUint16(offset, false)
      offset += 2
      if (marker === 0xffe1) {
        if (view.getUint32((offset += 2), false) !== 0x45786966) {
          return returnCallback(-1)
        }
        let little = view.getUint16((offset += 6), false) === 0x4949
        offset += view.getUint32(offset + 4, little)
        let tags = view.getUint16(offset, little)
        offset += 2
        for (let i = 0; i < tags; i++) {
          if (view.getUint16(offset + i * 12, little) === 0x0112) {
            return returnCallback(view.getUint16(offset + i * 12 + 8, little))
          }
        }
      } else if ((marker & 0xff00) !== 0xff00) {
        break
      } else {
        offset += view.getUint16(offset, false)
      }
    }
    return returnCallback(-1)
  }
  reader.readAsArrayBuffer(file)
})

Vue.filter('checkFileType', fileType => {
  fileType = fileType.toLowerCase()
  let fileTypes = [
    'image/png',
    'image/gif',
    'image/jpg',
    'image/jpeg',
    'image/bmp'
  ]

  return fileTypes.indexOf(fileType) > -1
})
