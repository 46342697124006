/**
 * Import API
 */
import statsAPI from '../../services/api/modules/stats'

/**
 * Declare Variable
 */
const state = {
  ready: false,
  stats: {
    'checkins-today' : {
      id: 'checkins-today',
      number: 0,
      text: 'Check-ins today'
    },
    'checkins-alltime' : {
      id: 'checkins-alltime',
      number: 0,
      text: 'Checkins to date'
    },
    'matches' : {
      id: 'matches',
      number: 0,
      text: 'Matches'
    },
    'locations' : {
      id: 'locations',
      number: 0,
      text: 'S2M Network locations'
    },
    'countries' : {
      id: 'countries',
      number: 0,
      text: 'Countries'
    },
    'questions' : {
      id: 'questions',
      number: 0,
      text: 'Questions asked to date'
    },
    'answers' : {
      id: 'answers',
      number: 0,
      text: 'Answers given to date'
    }
  }
}

const getters = {}

const actions = {
  /**
   * Obtain all time stats
   *  nr of checkins, matches, active countries, questions and answers
   *
   * @param context
   */
  obtainStats: context => {
    statsAPI
      .getAllTimeStats()
      .then(res => {
        context.commit('stats_load', { stats: res.data.Results })
      })
      .then(res => {
        context.commit('stats_ready')
      })
      .catch(error => console.log(error))
  }
}
const mutations = {
  /**
   * Indicate that all required location information is available
   *
   * @param state
   */
  stats_ready: state => {
    state.ready = true
  },

  /**
   * Set the list of available all time stats
   *
   * @param state
   * @param stats
   */
  stats_load: (state, { stats }) => {
    // Note: We do not use nr of locations from the stats call because that number is not correct.
    [
      {
        id: 'checkins-alltime',
        number: stats.NrOfCheckins
      },
      {
        id: 'matches',
        number: stats.NrOfMatches
      },
      {
        id: 'countries',
        number: stats.NrOfActiveCountries
      },
      {
        id: 'questions',
        number: stats.NrOfQuestions
      },
      {
        id: 'answers',
        number: stats.NrOfAnswers
      }
    ].forEach((item) => {
      state.stats[item.id].number = item.number;
    });
  },

  /**
   * Update or set the checkins count for today
   *
   * @param state
   * @param count
   */
  stats_set_checkins_today: (state, { count }) => {
    state.stats['checkins-today'].number = count;
  },

  /**
   * Update or set the locations count
   *
   * @param state
   * @param count
   */
  stats_set_locations: (state, { count }) => {
    state.stats['locations'].number = count;
  },

  /**
   * Increase the check in counters
   *
   * @param state
   */
  stats_add_checkin: state => {
    state.stats['checkins-alltime'].number++;
    state.stats['checkins-today'].number++;
  }
}

/**
 * Export
 */
export default {
  state,
  getters,
  actions,
  mutations
}
