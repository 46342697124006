import { profileBaseUrl } from '../../config'

export default ({ id, filename }) => {
  let img = Object.assign({ id, filename })

  /**
   * List of available profile image sizes
   *
   * @type {{}}
   */
  const availableProfileImageSizes = {
    '38': '38x38',
    '84': '84x84',
    '120': '120x120',
    '150': '150x150',
    '300': '300x300'
  }

  /**
   * Determine the optimal image size
   *
   * @param size
   */
  const getBestImageSizeFit = size => {
    let sizeKeys, last

    // Enforce int
    size = parseInt(size)

    // Exact matches are our favourites
    if (availableProfileImageSizes[size]) {
      return availableProfileImageSizes[size]
    }

    // Otherwise rotate through the available sizes
    // And select the one that is one larger than required
    sizeKeys = Object.keys(availableProfileImageSizes)
    for (let i = 0; i < sizeKeys.length; i++) {
      if (parseInt(sizeKeys[i]) > size) {
        return availableProfileImageSizes[sizeKeys[i]]
      }
    }

    // Is the image larger than our available size?
    // then select our maximum size
    last = sizeKeys.length - 1
    return availableProfileImageSizes[sizeKeys[last]]
  }

  /**
   * Get the default fallback profile image
   *
   * @return {*}
   */
  img.defaultImageSrc = () => {
    return '/img/no_picture.svg'
  }

  /**
   * Get the profile image src
   *
   * @param size
   * @return {string}
   */
  img.src = (size = 150) => {
    if (img.filename === '') {
      return img.defaultImageSrc()
    }

    size = getBestImageSizeFit(size)
    return profileBaseUrl + img.id + '_' + img.filename + '_' + size + '.jpg'
  }

  return img
}
