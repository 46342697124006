<template>
  <figure class="image">
    <img
      src="/img/spacer1_1.gif"
      class="is-rounded has-shadow"
      :style="{
        backgroundImage: mImageSrc,
        backgroundColor: chosenRandomColor
      }"
      :alt="itemName"
    />

  </figure>
</template>

<script>
import ProfileImg from "../services/models/profileImg";
export default {
  name: 'AvatarImage',

  props: {
    imageSrc: {
      type: String,
      default: ''
    },
    itemName: {
      type: String,
      required: true
    },
    itemId: {
      type: Number,
      required: true
    },
    photoSize: {
      type: Number,
      default: 84
    },
    fixedColorIndex: {
      type: [Boolean, Number],
      default: false
    }
  },

  data() {
    return {
      mImageSrc: '',
      chosenRandomColor: this.randomBgColor()
    }
  },

  watch: {
    imageSrc: {
      immediate: true,
      handler: function(val) {
        this.mImageSrc = val ?
          `url(${this.getImageSource()}), url(${this.generateAvatar()})`
          :
          `url(${this.generateAvatar()})`
      }
    },
  },

  methods: {
    getImageSource() {
      let img = ProfileImg({ id: this.itemId, filename: this.imageSrc });
      console.log(img.src(this.photoSize))
      return img.src(this.photoSize);
    },

    generateAvatar() {
      var initialsArr = this.itemName.split(' ')
      var initials = initialsArr[0].charAt(0).toUpperCase()
      initials += initialsArr.length > 1 ? initialsArr[initialsArr.length - 1].charAt(0).toUpperCase() : ''
      var canvas = document.createElement('canvas');
      canvas.width = this.photoSize;
      canvas.height = this.photoSize;

      // Get the drawing context
      var ctx = canvas.getContext('2d');
      ctx.beginPath();
      ctx.rect(0, 0, this.photoSize, this.photoSize)
      ctx.closePath();
      ctx.fillStyle = this.chosenRandomColor;
      ctx.fill();
      ctx.fillStyle = "white";
      ctx.font = "bold 30px Arial";
      ctx.textAlign = 'center';
      ctx.fillText(initials, this.photoSize / 2, this.photoSize / 2 + 13);
      return canvas.toDataURL();
    },

    randomBgColor() {
      const ARR_COLORS = ['#4C9B9B', '#0B757A', '#CFE4E5', '#78FFDA', '#95c11e', '#fec632', '#fec632', '#00baff', '#ff6600']
      if (this.fixedColorIndex !== false && Number(this.fixedColorIndex) <= ARR_COLORS.length - 1) {
        return ARR_COLORS[this.fixedColorIndex]
      }
      return ARR_COLORS[Math.floor(Math.random() * ARR_COLORS.length)]
    },
  }
}
</script>

<style lang="scss" scoped>
.image {
  position: relative;
  aspect-ratio: 1;
  margin: 0 !important;
  img {
    background-size: cover;
    background-position: -0.5px -0.5px;
  }
}
</style>