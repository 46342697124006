<template>
  <div class="s-slide s-map">
    <div class="s-map__title s-slide__titleTop">
      A world full of opportunities for you
    </div>
    <s2m-stats-overlay></s2m-stats-overlay>
  </div>
</template>

<script>
import Vue from 'vue'
import statsOverlay from '../stats/GroupedOverlay'

export default {
  name: 'StaticMap',
  components: {
    's2m-stats-overlay': statsOverlay
  }
}
</script>

<style scoped lang="scss">
.s-map {
  position: relative;
  width: 100%;
  height: 96vh;
  background-image: url("/img/tvscherm-kaart-hd.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .s-map__title {
    position: absolute;
    top: 5vh;
    text-shadow: 3px 3px white;
  }
}
</style>
