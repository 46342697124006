<template>

  <div class="c-meeting" :class="colorClass">
    <div class="c-meeting__content">

      <div class="c-meeting__name">
        {{ data.name }}
      </div>
      <div class="c-meeting__activity" v-if="data.subject !== ''">
        {{ data.subject }}
      </div>

    </div>
  </div>

</template>

<script>
  export default {
    name: 'Meeting',
    props: ['data'],
    computed: {
      colorClass() {
        return 'c-meeting--'+this.data.color;
      }
    }
  };
</script>