/**
 * Import Dependency
 */
import Card from '../../services/models/card'
import { flattenArray, shuffle, uniqueArray } from '../../services/helpers/array'
import { capitalizeFirstLetter } from '../../services/helpers/string'
import store from '../../store'
import { isSmallThanFullHD } from '../../services/helpers/mediaQueries'

/**
 * Import API
 */
import checkinAPI from '../../services/api/modules/checkin'

/**
 * Declare Variable
 */
const state = {
  cards: [], // cards for the get connected page
  page: 1, // position of cardsets
  ticker: 1, // position of the ticker
  tags: [], // list of tags for the tag cloud
  ready: false, // all base data loaded?
  checkins: [] // local checkins, used as pup-up queue
}

const getters = {
  getCardCount: state => state.cards.length,
  getCardsPageNumber: state => state.page,
  getCards: state => state.cards
}

const actions = {
  getKnowledgeInfo: context => {
    checkinAPI
      .getLocationCheckinData(store.state.location.id)
      .then(res => {
        // Process profile info into Cards
        let cards = res.data.Results.map(mapProfileToCard) // .filter(notATaglessCards) // slice(0, 10)

        context.commit('updateCards', {
          cards: cards
        })

        context.commit('updateTags')

        context.commit('shuffleCards')
        context.commit('shuffleTags')

        // Signal that the people info is ready
        context.commit('knowledgeReady')
      })
      .catch(error => console.log(error))
  },
  nextKnowledgePage: context => {
    context.commit('nextKnowledgePage')

    if (context.state.page === 1) {
      context.commit('shuffleCards')
    }
  },
  tickerProgress: context => {
    context.commit('tickerProgress')
  },
  addLocalCheckin: (context, { checkin }) => {
    let card = mapProfileToCard(checkin)

    if (notATaglessCards(card)) {
      context.commit('addLocalCheckin', { card })
      context.commit('addCard', { card })
      context.commit('updateTags')
    }
  },
  shuffleKnowledge: context => {
    context.commit('shuffleCards')
    context.commit('shuffleTags')
  }
}

const mutations = {
  knowledgeReady: state => {
    state.ready = true
  },
  /**
   * We show 2 or 3 profiles per page.
   * If we've not shown them all, go to the next page.
   * Otherwise go back to page 1
   * The last page may contain only 1 or 2 profiles
   *
   * @param state
   */
  nextKnowledgePage: state => {
    if (state.cards.length > state.page * (isSmallThanFullHD() ? 2 : 3)) {
      state.page++
    } else {
      state.page = 1
    }
  },
  tickerProgress: state => {
    if (state.cards.length > state.ticker + 1) {
      state.ticker++
    } else {
      state.ticker = 1
    }
  },
  shuffleCards: state => {
    state.cards = shuffle(state.cards)
  },
  shuffleTags: state => {
    state.tags = shuffle(state.tags)
  },
  updateCards: (state, { cards }) => {
    state.cards = cards
  },
  updateTags: state => {
    state.tags = uniqueArray(flattenArray(state.cards.map(mapCardTags)))
  },
  addCard: (state, { card }) => {
    state.cards.push(card)
  },
  addLocalCheckin: (state, { card }) => {
    state.checkins.push(card)
  },
  clear_current_popup: state => {
    state.checkins.shift()
  }
}

/**
 * Export
 */
export default {
  state,
  getters,
  actions,
  mutations
}

/**
 * Map the profile data from the S2M API to the data we need for a card
 *
 * @param input
 * @return {Card}
 */
let mapProfileToCard = input => {
  let tags = input.Tags.split(',').filter(applyTagLengthRestrictions)

  return {
    id: input.ProfileId,
    name: capitalizeFirstLetter(input.ProfileName),
    image: removeImageExtension(input.ProfileImage),
    workingOn: capitalizeFirstLetter(input.WorkingOn),
    tags: tags || ''
  }
}

/**
 * Remove the image extension
 *
 * @param {string} string
 * @return {string}
 */
let removeImageExtension = string => string.slice(0, -4)

/**
 * A tag string should be ignored if shorter than 4 characters, or longer than 29.
 *
 * @param {string} string
 * @return {boolean}
 */
let applyTagLengthRestrictions = string => string.length > 3 && string.length < 30

/**
 * Use to ignore Cards with zero tags
 *
 * @param {Card} Card
 * @return {boolean}
 */
let notATaglessCards = Card => Card.tags.length !== 0

/**
 * Get the tags from the card
 *
 * @param Card
 * @return {Array}
 */
let mapCardTags = Card => Card.tags
