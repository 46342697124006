<template>
  <div class="s-start s-slide">
    <div class="s-slide__logo">
      <img ref="logo" class="s-slide__logo--greetings" :src="logo">
    </div>
    <div class="s-slide__titleMiddle">
      {{ msg }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Greetings',
  data() {
    return {
      msg: ''
    }
  },
  computed: {
    logo: function() {
      return this.$store.state.location.logo
    }
  },
  mounted() {
    this.$refs.logo.onload = r => {
      if (typeof this.$refs.logo !== 'undefined' && this.$refs.logo.offsetWidth > window.innerWidth - 100) {
        this.$refs.logo.className += '--adjustWidth'
      }
    }

    let next = 0,
      getTimeBasedGreeting = () => {
        let curHr = new Date().getHours()

        if (curHr < 12) {
          return 'Good morning.'
        }
        if (curHr < 18) {
          return 'Good afternoon.'
        }

        return 'Good evening.'
      },
      updateMessage = _ => {
        let words = ['Hello!', getTimeBasedGreeting(), 'Welcome!', 'Have we met already?', 'Nice to see you here!']

        // Select next item, or go back to 0
        next = (next + 1) % words.length
        this.msg = words[next]
      }

    updateMessage()
    setInterval(updateMessage, 10 * 1000)
  }
}
</script>

