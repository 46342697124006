// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'

/**
 * Import Dependencies
 */
import 'es6-promise/auto'
import router from './router'
import store from './store'
import { sync } from 'vuex-router-sync'
import './filters/imageFilters'

/**
 * Import Helpers
 */
import { Button, Select, Option } from 'element-ui'

/**
 * Import Main Vue Component
 */
import App from './App'
import versionChecker from './services/versionChecker'

/**
 * Global Config
 */
Vue.config.productionTip = false

sync(store, router)

/**
 * Register Element UI components
 */
Vue.component(Button.name, Button)
Vue.component(Select.name, Select)
Vue.component(Option.name, Option)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>',
  mounted: function() {
    versionChecker.run()

    router.push({
      name: 'Location'
    }).catch(() => { })
  }
})
