import axios from './api/config'
import { versionCheckInterval } from '../config'

/**
 * This service keeps track of the application version and auto updates when a new version is available.
 *
 */

let currentVersion

const updateCurrentVersionInfo = () => {
  currentVersion = localStorage.getItem('version') || 0
}

const handleCheckResponse = res => {
  updateCurrentVersionInfo()
  if (Number(res.data.version) !== Number(currentVersion)) {
    localStorage.setItem('version', res.data.version)
    window.location.reload(true)
  }
}

const runCheck = () => {
  axios
    .get('/version.json', {
      baseURL: window.location.origin
    })
    .then(handleCheckResponse)
    .catch(error => console.log(error))
}

const initVersionChecker = () => {
  updateCurrentVersionInfo()
  runCheck()

  setInterval(runCheck, versionCheckInterval * 1000 * 60)
}

export default {
  run: initVersionChecker,
  runCheck,
  currentVersion
}
