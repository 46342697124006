<template>
  <div class="startup parent">
    <div
      v-if="isLoading"
      class="content"
    >
      <div class="is-size-4 has-text-weight-bold">Loading data...</div>
    </div>
    <div
      v-if="!isLoading"
      class="content"
    >
      <div class="box is-info">
        <div class="is-size-4 has-text-weight-bold">Whoops! Something went wrong.</div>
        Please, load the TV location live dashboard again.<br />
        It's possible that the application key is incorrect.
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'location',
  data() {
    return {
      isLoading: true,
      applicationFound: true
    }
  },

  computed: {
    ...mapState('application', ['applicationKey']),
    ...mapGetters('application', ['applicationKeyFromStore']),
  },

  created() { },

  async mounted() {
    let application = null

    let _applicationKey = this.$route.params.appKey || this.applicationKeyFromStore
    if (_applicationKey) {
      application = await this.changeApplication(_applicationKey)
    }

    // Load TV network and location data
    let _dataLoad = await Promise.all([this.$store.dispatch('getAllCheckins'), this.$store.dispatch('obtainStats')])

    this.isLoading = false

    if (application) {
      router.push({
        name: 'Slides'
      }).catch(() => { })
    }
  },

  methods: {
    ...mapActions('application', ['changeApplication'])
  }
}
</script>
