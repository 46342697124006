import router from "./../router";

let slides = [],
  next = 0,
  uniqueToken = 0,
  timer = null,
  afterTimer = null,
  on = false,
  cbs = {
    before: [],
    after: []
  },

  navigateToNextSlide = () => {
    const nextSlide = slides[next];
    uniqueToken++;

    if ( on === false) {
      return;
    }

    // Before the first slide we run the cb's
    if (next === 0) {
      cbs.before.forEach((cb) => {
        cb();
      });
    }

    router.push({
      name: nextSlide.name,
      params: Object.assign({}, nextSlide.params, { slideIdentifier: uniqueToken })
    }).catch(() => {});

    if (timer !== null) {
      stop();
    }
    timer = setTimeout(navigateToNextSlide, nextSlide.duration);

    // Next or reset to start
    next = (next + 1) % slides.length;

    // At the end we run the cb's
    if (next === 0) {
      afterTimer = setTimeout(() => {
        cbs.after.forEach((cb) => {
          cb();
        });
      }, nextSlide.duration - 5);
    }
  },
  config = (input) => {
    slides = input;
  },
  run = () => {
    next = 0;
    on = true;
    navigateToNextSlide();
  },
  stop = () => {
    clearTimeout(afterTimer);
    clearTimeout(timer);
  },
  reset = () => {
    stop();
    on = false;
    next = 0;
  },
  before = (cb) => {
    cbs.before.push(cb);
  },
  after = (cb) => {
    cbs.after.push(cb);
  };

export default {
  config,
  run,
  stop,
  reset,
  before,
  after,
  next : navigateToNextSlide
};



