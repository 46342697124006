<template>
  <div class="s-welcome s-slide">
    <div class="s-slide__logo">
      <img ref="logo" class="s-slide__logo--welkom" :src="logo">
    </div>
    <div class="s-slide__titleMiddle">
      Welcome to <br /> {{ location }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Welcome',

  computed: {
    ...mapState({
      logo: state => state.location.logo,
      location: state => state.location.name
    })
  },

  mounted() {
    this.$refs.logo.onload = r => {
      if (typeof this.$refs.logo !== 'undefined' && this.$refs.logo.offsetWidth > window.innerWidth - 100) {
        this.$refs.logo.className += '--adjustWidth'
      }
    }
  }
}
</script>

