<template>
  <div>Loader...</div>
</template>

<script>
  export default {
    name: "loader"
  }
</script>

<style scoped>

</style>