/**
 * Import Dependency
 */

/**
 * Import API
 */
import checkinAPI from '../../services/api/modules/checkin';

/**
 * Declare Variable
 */
const state = {
  checkins: []
};

const getters = {
  getCheckinCount: state => state.checkins.length,
  getCheckins: state => state.checkins
};

const actions = {
  /**
   * Add a checkin
   *
   * @param context
   * @param profile
   */
  addCheckin: (context, {checkin}) => {
    context.commit('addCheckin', {checkin: mapProfileToCheckin(checkin)});
    context.commit('stats_add_checkin');
  },

  /**
   * Obtain all checkins from the server and convert them to Checkin objects
   *
   * @param context
   */
  getAllCheckins: async (context) => {
    return checkinAPI.getAllCurrentCheckinData()
      .then(res => {
        // console.log(res);
        context.commit("setCheckins", {checkins: res.data.Results.map(mapProfileToCheckin)});

        // Keep track of the checkin count as a stat
        context.commit('stats_set_checkins_today', {count: res.data.Results.length});
      });
  }
};
const mutations = {
  /**
   * Add a checkin
   *
   * @param state
   * @param checkin
   */
  addCheckin: (state, {checkin}) => {
    state.checkins.push(checkin);
  },

  /**
   * Set the initial array of checkins
   *
   * @param state
   * @param checkins
   */
  setCheckins: (state, {checkins}) => {
    state.checkins = checkins;
  }
};

/**
 * Map the profile data from the S2M API to the data we need for a card
 *
 * @param input
 * @return {Card}
 */
let mapProfileToCheckin = (input) => {
  return {
    Latitude: input.Latitude,
    Longitude: input.Longitude,
    LocationId: input.LocationId,
    ProfileImage: removeImageExtension(input.ProfileImage),
    ProfileId: input.ProfileId,
    Id: input.Id
  };
};

/**
 * Remove the image extension
 *
 * @param {string} string
 * @return {string}
 */
let removeImageExtension = (string) => string.slice(0, - 4);

/**
 * Export
 */
export default {
  state,
  getters,
  actions,
  mutations
};