/**
 * Import Dependency
 */
import axios from '../config';

/**
 * Declare Variable
 */

const resourceRoot = 'stats';

/**
 * Export
 */
export default {
  getAllTimeStats() {
      return axios.get('/allTimeStats.json', {
        baseURL: window.location.origin
      })
    // let response = axios.get(`${resourceRoot}/platform/alltime`, {
    //   baseURL: 'https://old.seats2meet.com/api/',
    //   headers: {
    //     'token': 99345299
    //   }
    // });
    // return response;
  }
}