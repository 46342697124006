/**
 * Import Dependency
 */
import axios from '../config'

/**
 * Declare Variable
 */

const resourceRoot = 'application'
/**
 * Export
 */
export default {
  async getApplicationByKey(appKey) {
    return await axios.get(`${resourceRoot}/${appKey}/state`)
  }
}