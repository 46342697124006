<template>
  <div class="s-slide s-coffee">
    <div class="s-coffee__logo s-slide__logo">
      <img class="" src="../../assets/koffieicoon.svg">
    </div>
    <div class="s-coffee__title s-slide__titleMiddle">
      Why not offer coffee to the person next to you?
    </div>
  </div>
</template>

<script>
export default {
  name: 'Coffee'
}
</script>
