/**
 * Import Dependency
 */
import axios from 'axios';
import {api} from './../../config';

/**
 * Config
 */
axios.defaults.timeout = api.timeout;
axios.defaults.baseURL = api.baseUrl;
axios.defaults.headers.common = Object.assign(
  axios.defaults.headers.common,
  api.headers
);

/**
 * Declare Variable
 */

/**
 * Error Logging
 */
axios.interceptors.request.use(config => {
  return config;
}, error => {
  console.group('[Axios][Interceptor] Request Error');
  console.log(error);
  console.groupEnd();
  return Promise.reject(error);
});

axios.interceptors.response.use(data => {
  return data
}, error => {
  console.group('[Axios][Interceptor] Response Error');
  console.log(error);
  console.groupEnd();
  return Promise.reject(error);
});

export default axios;