/**
 * Flatten an array
 *
 * @param array
 * @return {*[]}
 */
export const flattenArray = array => Array.isArray(array)
  ? [].concat(...array.map(flattenArray))
  : array;

/**
 * Remove duplicate values from an array
 *
 * @param array
 * @return {*[]}
 */
export const uniqueArray = array => [...new Set(array)];

/**
 * Shuffle array data - Fisher-Yates shuffle
 *
 * @param array
 * @return {*}
 */
export const shuffle = (array) => {
  let counter = array.length;

  // While there are elements in the array
  while (counter > 0) {
    let temp,
      // Pick a random index
      index = Math.floor(Math.random() * counter);

    // Decrease counter by 1
    counter --;

    // And swap the last element with it
    temp = array[counter];
    array[counter] = array[index];
    array[index] = temp;
  }

  return array;
};

/**
 * Get random value from an array
 *
 * @param array
 * @return {*}
 */
export const getRandom = (array) => {
  return array[Math.floor(Math.random() * array.length)];
};

/**
 * Get a different random value than the current one
 *
 * @param array
 * @param current
 * @return {*}
 */
export const getDifferentRandom = (array, current) => {
  if (array.length === 0) {
    return;
  } else if (array.length === 1) {
    return array[0];
  } else if (array.indexOf(current) > -1) {
    let next = 0;
    do {
      next = getRandom(array);
    } while (next === current);
    return next;
  } else {
    return getRandom(array);
  }
};