<template>
  <div class="s-knowledge s-slide">
    <div class="s-slide__titleTop s-knowledge__title">
      Selection of Knowledge
    </div>

    <div class="s-knowledge__tags">
      <span
        class="c-tag c-tag--knowledge"
        v-for="(tag, i) in tags"
        v-html="tag"
        :key="'t_'+i"
      ></span>
    </div>

    <div
      class="s-knowledge__undertitle"
      v-if="showTagCount"
    >
      {{ tagCount }} total knowledge opportunities
    </div>

  </div>
</template>

<script>
import { isSmallThanFullHD } from "../../services/helpers/mediaQueries";
import store from '@/store';

export default {
  name: 'KnowledgeTags',
  data() {
    return {
      msg: 'KnowledgeTags slide',
    };
  },
  computed: {
    tags() {
      return this.$store.state.knowledge.tags.slice(0, (isSmallThanFullHD() ? 9 : 14));
    },
    showTagCount() {
      return this.$store.state.knowledge.tags.length > 10;
    },
    tagCount() {
      return this.$store.state.knowledge.tags.length;
    }
  },
  beforeRouteEnter(to, from, next) {
    if (store.state.knowledge.tags.length === 0) {
      next(false);
    } else {
      next();
    }
  },
};
</script>
