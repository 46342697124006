/**
 * Import Dependency
 */
import Vue from 'vue';
import Vuex from 'vuex';

/**
 * Root Scope of VUEX
 */
import * as getters from './getters'
import {state, actions, mutations} from './base'

/**
 * Module Scope of VUEX
 */
import application from './modules/application'
import location from './modules/location'
import knowledge from './modules/knowledge'
import checkins from './modules/checkins'
import stats from './modules/stats';
import meetings from './modules/meetings';

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  modules: {
    application,
    location,
    knowledge,
    checkins,
    stats,
    meetings
  },
  strict: true
})