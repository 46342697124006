<template>

  <div
    class="c-card"
    :class="typeClass"
  >
    <div class="c-card__profile">
      <avatar-image
        class="c-card__profpic m-0"
        :itemId="data.id"
        :itemName="data.name"
        :imageSrc="data.image"
        :photoSize="size"
      />
      <!-- <img
        class="c-card__profpic"
        :src="data.imageSrc(size)"
      /> -->
    </div>

    <div class="c-card__content">

      <div class="c-card__name">
        {{ data.name }}
      </div>
      <div
        class="c-card__activity"
        v-if="data.workingOn !== ''"
      >
        {{ data.workingOn }}
      </div>

      <div
        class="c-card__tags"
        v-if="showTags"
      >
        <span
          class="c-tag"
          v-for="(tag, i) in data.tags.slice(0, 5)"
          v-html="tag"
          :key="'ct'+i"
        ></span>
        <span
          class="c-tag c-tag--more"
          v-if="more > 0"
        >+ {{ more }}</span>
      </div>
    </div>
  </div>

</template>

<script>
import AvatarImage from './AvatarImage.vue'
export default {
  components: { AvatarImage },
  name: 'Card',
  props: ['data', 'type'],
  computed: {
    typeClass() {
      return 'c-card--' + this.type
    },
    more() {
      return this.data.tags.length - 5
    },
    showTags() {
      return this.type !== 'horizontal'
    },
    // Requested image size in px
    size() {
      return this.type !== 'popup' ? 200 : 300
    }
  }
}
</script>