<template>
  <div class="gridLayout">
    <transition
      name="fade"
      mode="out-in"
    >
      <keep-alive include="google-map">
        <router-view :key="$route.params.slideIdentifier"></router-view>
      </keep-alive>
    </transition>

    <s2m-ticker></s2m-ticker>
    <s2m-popup></s2m-popup>
  </div>
</template>

<script>
import Popup from '@/components/Popup'
import Ticker from '@/components/Ticker'

import routeSlider from '@/services/routeSlider'
import { routeSliderConfig } from '@/config'

let currentDay = new Date().getDate() // day as number of day in month

export default {
  name: 'slides',
  components: {
    's2m-ticker': Ticker,
    's2m-popup': Popup
  },
  computed: {
    doneLoading() {
      return this.$store.state.location.ready && this.$store.state.knowledge.ready
    }
  },

  mounted() {
    this.$store.dispatch('initLocation')
    this.$store.dispatch('getKnowledgeInfo')

    // Always get all locations, because we need them also for the map and stats
    this.$store.dispatch('getAllLocations');

    // Get all meetings for this location
    this.$store.dispatch('getLocationMeetings')

    routeSlider.config(routeSliderConfig)
    routeSlider.run()
    routeSlider.after(() => {
      if ((currentDay !== new Date().getDate()) && new Date().getHours() > 3) {
        window.location.assign('/')
        setTimeout(() => {
          window.location.reload(true)
        }, 1)
        return
      }

      this.$store.dispatch('shuffleKnowledge')
    })

    // When moving away from the slider, kill it.
    this.$router.beforeEach((to, from, next) => {
      if (!to.path.startsWith('/slide')) {
        routeSlider.reset()
      }
      next()
    })
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>