/**
 * The base url of the location logo img
 *
 * @type {string}
 */
export const logoBaseUrl = 'https://az691754.vo.msecnd.net/website/'

/**
 * The base url of the profile img
 *
 * @type {string}
 */
export const profileBaseUrl = 'https://s2mup.s3.amazonaws.com/'

/**
 * The refresh rate of the ticker in ms
 *
 * @type {number}
 */
export const tickerSpeed = 10000

/**
 * Version checker interval in minutes
 *
 * @type {number}
 */
export const versionCheckInterval = 15

/**
 *
 * @type {number}
 */
export const popupTimer = 5000

/**
 * S2M API Credentials
 *
 * @type {{}}
 */
export const api = {
  timeout: 20000,
  baseUrl: process.env.VUE_APP_API_BASE_URL + '/api/v1/', // 'https://api.cyberdigma.nl/api/v1/',
  headers: {
    apitoken: process.env.VUE_APP_API_KEY
  }
}

/**
 * Route Slider Config
 *  Takes an array of {name: routeName, duration: ms, params: routeParams}
 *
 * @type {*[]}
 */
export const routeSliderConfig = [
  {
    name: 'Welcome',
    duration: 5000
  },
  {
    name: 'StaticMap',
    duration: 20000
  },
  {
    name: 'Greetings',
    duration: 5000
  },
  {
    name: 'KnowledgeTags',
    duration: 6000
  },
  {
    name: 'Coffee',
    duration: 5000
  },
  {
    name: 'GetConnected',
    duration: 6000
  },
  {
    name: 'Meetings',
    duration: 6000
  }
]

/**
 * Use dummy Knowledge data
 *  We do not always have check-in data available from the API
 */
export const useDummyData = false
