/**
 * Import Dependency
 */
import axios from '../config';

/**
 * Declare Variable
 */

const resourceRoot = 'schedule';

/**
 * Export
 */
export default {

  /**
   * Get the meeting events for a location
   * 
   * @param {number} locationId
   */
  getAllLocationMeetings({ locationId, meetingtypeId = 1, startDate = new Date(), endDate = new Date() } = {}) {
    startDate = startDate
    endDate = endDate
    return axios.get(`${resourceRoot}`, {
      params: {
        locationId,
        meetingtypeId,
        startDate,
        endDate
      }
    }).then((res) => {
      return res.data.Spaces.filter(space => !space.SpaceName.toLowerCase().startsWith('[hidden]') && !space.SpaceName.toLowerCase().startsWith('(hidden)')) // res.data.filter(e => e.Id.startsWith('R'));
    });
  }
}