<template>
  <div class="s-getConnected s-slide">

    <div class="s-slide__titleTop">
      Get Connected!
    </div>

    <div class="cards">

      <checkin-card
        v-for="card in cards"
        :data="card"
        :key="card.id"
      ></checkin-card>

    </div>
  </div>
</template>

<script>
import Card from '../Card';

import { isSmallThanFullHD } from '@/services/helpers/mediaQueries';
import store from '@/store';

export default {
  name: 'GetConnected',
  data() {
    return {
      msg: 'GetConnected slide'
    }
  },
  computed: {
    cards() {
      let start = (this.$store.state.knowledge.page - 1) * (isSmallThanFullHD() ? 2 : 3);
      return this.$store.state.knowledge.cards.slice(start, start + (isSmallThanFullHD() ? 2 : 3))
    }
  },
  components: {
    'checkin-card': Card
  },
  beforeRouteEnter(to, from, next) {
    if (store.state.knowledge.cards.length === 0) {
      next(false);
    } else {
      next();
    }
  },
  beforeDestroy() {
    this.$store.dispatch('nextKnowledgePage')
  }
}
</script>

