/**
 * Import Dependency
 */
import axios from '../config'
import { useDummyData } from '../../../config'

/**
 * Declare Variable
 */
const resourceRoot = 'checkin'

/**
 * Export
 */
export default {
  getAllCurrentCheckinData() {
    return axios.get(`${resourceRoot}`, {
      params: {
        page: 0,
        itemsPerPage: 0
      }
    })
  },

  getLocationCheckinData(id) {
    if (useDummyData) {
      return axios.get('/dummyKnowledgeData.json', {
        baseURL: window.location.origin
      })
    }
    
    return axios.get(`${resourceRoot}/location/${id}`, {
      params: {
        page: 0,
        itemsPerPage: 0
      }
    })
  },
}