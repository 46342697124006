<template>
  <div class="s-meetings s-slide">

    <div class="s-slide__titleTop">
      Today in the meetingspaces
    </div>

    <div class="meetings">
    
      <s2m-meeting v-for="meeting in meetings" :data="meeting" :key="meeting.id"></s2m-meeting>

    </div>
  </div>
</template>

<script>
import Meeting from '../Meeting';

import { isSmallThanFullHD } from '@/services/helpers/mediaQueries';
import { shuffle } from '@/services/helpers/array';
import store from '@/store';

export default {
  name: 'Meetings',
  computed: {
    meetings() {
      let start = (this.$store.state.meetings.page - 1) * (isSmallThanFullHD() ? 2 : 3);
      let colors = this.getRandomColorOrder();

      let meetings = this.$store.state.meetings.meetings
        .slice(start, start + (isSmallThanFullHD() ? 2 : 3));

      // Deep copy so we can make temp changes to the object
      meetings = JSON.parse(JSON.stringify(meetings));

      return meetings.map((meeting) => {
          meeting.color = colors.shift();
          return meeting;
        });
    }
  },
  components: {
    's2m-meeting': Meeting
  },
  methods: {
    getRandomColorOrder() {
      return shuffle(['secondaryColor','primaryColor','tertiaryColor']);
    }
  },
  beforeRouteEnter (to, from, next) {
    if (store.state.meetings.meetings.length === 0) {
      next(false);
    } else {
      next();
    }
  },
  beforeDestroy() {
    this.$store.dispatch('nextMeetingsPage')
  }
}
</script>

