/**
 * Import Dependency
 */
import axios from '../config'
import { api } from '../../../config'
import store from '@/store'

/**
 * Declare Variable
 */

const resourceRoot = 'location'

/**
 * Export
 */
export default {
  getAllLocationsData() {
    return axios.get(`${resourceRoot}`, {
      params: {
        channelId: 1,
      },
      headers: getHeaders(store)
    })
  },

  getLocationData({ id }) {
    return axios.get(`${resourceRoot}/${id}`)
  },

  getLocationLogo({ id }) {
    return axios.get(`${resourceRoot}/${id}/logo`, {
      headers: getHeaders(store)
    })
  }
}

  
export function getHeaders(store) { 
  let headers = Object.assign(
    axios.defaults.headers.common,
    api.headers
  )

  headers.apitoken = store.getters['application/applicationApiToken'] || headers.apitoken

  if (store.getters['application/applicationProfileToken']) {
    headers['profiletoken'] = store.getters['application/applicationProfileToken']
  }
  
  return headers
}